<!-- @component Accordion for showing and hiding details, typical for mobile layouts. -->

<script lang="ts" context="module">
  export type Item = {
    icon?: any;
    headline: string;
    subline: string;
  };
</script>

<script lang="ts">
  import AccordionListItem from "./AccordionListItem.svelte";

  export let items: Item[];
</script>

{#each items as { icon, headline, subline }}
  <AccordionListItem {icon} {headline}>
    <p>{subline}</p>
  </AccordionListItem>
{/each}
