<script lang="ts">
  import * as _tweets from "$lib/assets/tweets/tweets.json";
  import FadeLeftRight from "./FadeLeftRight.svelte";
  import HorizontalAutoScroll from "./HorizontalAutoScroll.svelte";
  import Testimonial from "./Testimonial.svelte";
  import type { Tweet } from "./tweet";
  import MarketingButton from "../MarketingButton.svelte";

  const tweets: Tweet[] = (_tweets as any).default;
  const evenTweets = tweets.filter((_, index) => index % 2 === 0);
  const oddTweets = tweets.filter((_, index) => index % 2 === 1);
</script>

<div class="marketing-container">
  <div
    class="mb-12 flex flex-col sm:flex-row justify-between items-start sm:items-end gap-y-6"
  >
    <div class="marketing-h3">
      <span class="text-brand-green">Join</span> Modal's developer<br
      />community
    </div>
    <MarketingButton href="/slack" newTab>Modal Community Slack</MarketingButton
    >
  </div>

  <FadeLeftRight color="black">
    <HorizontalAutoScroll duration="120s" gap="0px">
      {#each evenTweets as tweet (tweet.url)}
        <Testimonial {tweet} />
      {/each}
    </HorizontalAutoScroll>

    <div class="hidden md:block">
      <HorizontalAutoScroll duration="100s" gap="0px" reverse>
        {#each oddTweets as tweet (tweet.url)}
          <Testimonial {tweet} hideTopBorder />
        {/each}
      </HorizontalAutoScroll>
    </div>
  </FadeLeftRight>
</div>
