<script lang="ts">
  import CartesiaLogo from "$lib/assets/logos/customers/CartesiaLogo.svelte";
  import CognitionLogo from "$lib/assets/logos/customers/CognitionLogo.svelte";
  import ContextualLogo from "$lib/assets/logos/customers/ContextualLogo.svelte";
  import CursorLogo from "$lib/assets/logos/customers/CursorLogo.svelte";
  import QuoraLogo from "$lib/assets/logos/customers/QuoraLogo.svelte";
  import RampLogo from "$lib/assets/logos/customers/RampLogo.svelte";
  import SubstackLogo from "$lib/assets/logos/customers/SubstackLogo.svelte";
  import SunoLogo from "$lib/assets/logos/customers/SunoLogo.svelte";
</script>

<!-- This outer black-background element clips the bottom of the hero video. -->
<div class="bg-black">
  <div class="marketing-container">
    <div
      class="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-8 justify-center items-center gap-0
      marketing-contained-negate-ml marketing-contained-negate-mr
      border-y min-[1400px]:border-x border-light-green/30 min-[1400px]:rounded-lg"
    >
      <div class="grid-item">
        <a href="/blog/ramp-case-study">
          <RampLogo />
        </a>
      </div>
      <div class="grid-item">
        <a target="_blank" rel="noreferrer" href="https://www.quora.com">
          <QuoraLogo />
        </a>
      </div>
      <div class="grid-item">
        <a href="/blog/substack-case-study">
          <SubstackLogo />
        </a>
      </div>
      <div class="grid-item">
        <a target="_blank" rel="noreferrer" href="https://www.cartesia.ai">
          <CartesiaLogo />
        </a>
      </div>
      <div class="grid-item">
        <a target="_blank" rel="noreferrer" href="https://cursor.sh/">
          <CursorLogo />
        </a>
      </div>
      <div class="grid-item">
        <a href="/blog/suno-case-study">
          <SunoLogo />
        </a>
      </div>
      <div class="grid-item">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.cognition-labs.com"
        >
          <CognitionLogo />
        </a>
      </div>
      <div class="grid-item">
        <a href="/blog/contextual-case-study">
          <ContextualLogo />
        </a>
      </div>
    </div>
  </div>
</div>

<style lang="postcss">
  .grid-item {
    @apply w-full h-full px-6 py-6 border-light-green/30 border-r;

    &:nth-child(2n) {
      @apply border-r-0;
    }

    &:nth-child(-n + 6) {
      @apply border-b;
    }

    @screen md {
      &:nth-child(4n + 2) {
        @apply border-r;
      }
      &:nth-child(5),
      &:nth-child(6) {
        @apply border-b-0;
      }
    }

    @screen xl {
      @apply !border-y-0;
      &:nth-child(2n) {
        @apply border-r;
      }
      &:last-child {
        @apply border-r-0;
      }
    }
  }

  a {
    @apply w-full h-full flex justify-center items-center fill-light-green opacity-60 transition-all hover:opacity-100;
  }
</style>
