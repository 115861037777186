<script lang="ts">
  import { analytics } from "$lib/analytics";

  let prompt = "";
  let currentImage =
    "https://modal-cdn.com/landing-image-generation-default-tulips.webp";
  let currentImageId = 0;
  let idCounter = 1;

  async function generateImage(prompt: string) {
    const id = idCounter++; // unique because JavaScript is single-threaded
    const encodedPrompt = encodeURIComponent(prompt);
    const response = await fetch(
      `https://modal-labs--flux-schnell-model-inference.modal.run/?prompt=${encodedPrompt}`,
      { method: "GET" },
    );
    const blob = await response.blob();
    if (id > currentImageId) {
      currentImage = URL.createObjectURL(blob);
      currentImageId = id;
    }
    analytics.track("Landing Page Interactive Image Generated");
  }

  let inflightRequests = 0;
  let lastRequestSentAt = 0;

  async function sendRequest(prompt: string) {
    inflightRequests++;
    lastRequestSentAt = Date.now();
    try {
      await generateImage(prompt);
    } finally {
      inflightRequests--;
    }
  }

  let nextRequestTimerId: ReturnType<typeof setTimeout>;

  function scheduleRequest(prompt: string) {
    // If there is already a scheduled request, cancel it
    clearTimeout(nextRequestTimerId);
    const now = Date.now();

    // Min delay of 100ms to avoid overloading the server
    // Exponential backoff to prevent requests from piling up
    const debounceDelay = 100 * Math.pow(2, inflightRequests);

    if (lastRequestSentAt + debounceDelay <= now) {
      // If enough time has passed since the last request, send a new request immediately
      sendRequest(prompt);
    } else {
      // Otherwise, schedule the next request at lastRequestTimestamp + debounceDelay
      const waitTime = lastRequestSentAt + debounceDelay - now;
      nextRequestTimerId = setTimeout(() => {
        sendRequest(prompt);
      }, waitTime);
    }
  }

  let firstRender = true;
  $: {
    if (firstRender) {
      firstRender = false;
    } else {
      scheduleRequest(prompt);
    }
  }
</script>

<div
  class="w-full max-w-[600px] md:max-w-full h-full md:h-[550px] bg-cover bg-center rounded-xl p-4 sm:p-7"
  style="background-image: url({currentImage})"
>
  <form
    on:submit|preventDefault={() => scheduleRequest(prompt)}
    class="flex w-full bg-light-green/60 rounded-full h-10 backdrop-blur-sm"
  >
    <input
      type="text"
      bind:value={prompt}
      placeholder="Type an image prompt (e.g. blue tulips)"
      class="flex-grow w-full min-w-0 pl-6 pr-2 h-full text-sm text-black !bg-transparent border-none focus:ring-0 placeholder:text-black/70"
    />
    <div class="p-0.5">
      <button
        type="submit"
        class="px-6 h-full rounded-full text-sm text-light-green bg-black hover:bg-gray-800 focus:outline-none transition-colors"
      >
        Generate
      </button>
    </div>
  </form>
</div>
