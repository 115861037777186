<script lang="ts">
  import VideoBackground from "$lib/ui/common/VideoBackground.svelte";
  import GetStartedOrBookDemo from "../GetStartedOrBookDemo.svelte";
</script>

<div class="marketing-container">
  <div class="flex flex-col md:flex-row gap-x-6 justify-between mt-24">
    <h1 class="marketing-h1 mb-4 text-center md:text-left">
      Run <span class="text-primary">anything</span><br /> in the cloud
    </h1>
    <div class="md:mt-4 md:mr-4 text-center text-lg md:text-left">
      Serverless cloud infrastructure<br /> for AI, ML, and data applications
    </div>
  </div>

  <div class="flex gap-4 justify-center md:justify-start mt-9">
    <GetStartedOrBookDemo />
  </div>

  <div
    class="mt-4 md:-mt-24 lg:-mt-32 xl:-mt-40 mb-8 md:mb-12 marketing-contained-negate-ml marketing-contained-negate-mr"
  >
    <div class="relative -z-10 w-full scale-[140%] aspect-[1600/910]">
      <VideoBackground poster="https://modal-cdn.com/tmp60ydwzz8_ea31268d.webp">
        <source
          src="https://modal-cdn.com/landscape-vids/Modal_Hero_3MB.mp4"
          type="video/mp4"
        />
      </VideoBackground>
    </div>
  </div>
</div>
