<script lang="ts">
  import ComputeCosts from "../ComputeCosts.svelte";
  import MarketingButton from "../MarketingButton.svelte";
  import ModalBarsVideo from "../ModalBarsVideo.svelte";
</script>

<div class="relative bg-dark-gray z-0 flex flex-col items-center">
  <div class="video-container">
    <ModalBarsVideo />
  </div>

  <div
    class="grid grid-cols-1 md:grid-cols-2 z-1 gap-5 marketing-container items-start w-full"
  >
    <div class="md:w-96">
      <div class="marketing-h3">
        Only pay when your<br /> code is
        <span class="text-primary">running</span>
      </div>
      <div class="mt-12">
        Scale up to hundreds of nodes and down to zero within seconds. Pay for
        actual compute, by the CPU cycle. With $30 of compute on us, every
        month.
      </div>
    </div>

    <section class="mt-20 md:mt-0">
      <ComputeCosts />
    </section>
  </div>

  <section
    class="mt-56 md:mt-64 pb-48 md:pb-80 marketing-container grid grid-cols-1 md:grid-cols-3 gap-x-5 gap-y-12 md:gap-y-32"
  >
    <div class="marketing-h3 md:col-span-3">
      For teams<br /> of all scales
    </div>

    <div class="max-w-[32ch]">
      <div class="marketing-h4 mb-4 md:mb-6">Starter</div>
      <div>For small teams and independent developers looking to level up.</div>
    </div>
    <div class="max-w-[32ch]">
      <div class="marketing-h4 mb-4 md:mb-6">Team</div>
      <div>For startups and larger organizations looking to scale quickly.</div>
    </div>
    <div class="max-w-[32ch]">
      <div class="marketing-h4 mb-4 md:mb-6">Enterprise</div>
      <div>
        For organizations prioritizing security, support, and reliability.
      </div>
    </div>

    <div>
      <MarketingButton href="/pricing">View Pricing</MarketingButton>
    </div>
    <div class="md:col-span-2"></div>
  </section>
</div>

<style lang="postcss">
  .video-container {
    @apply absolute inset-0 top-[33%] md:top-[5%] z-[-1];
  }

  .video-container::after {
    content: "";
    @apply absolute inset-0 bottom-[-1px];
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }
</style>
