<script lang="ts">
  import UseCaseLinks from "../UseCaseLinks.svelte";
</script>

<div class="bg-dark-gray">
  <div class="marketing-container">
    <div class="mb-14 text-center marketing-h4">Build anything with Modal</div>

    <UseCaseLinks color="dark-gray" />
  </div>
</div>
