<script lang="ts">
  import Landing from "$lib/ui/marketing/landing/Landing.svelte";
</script>

<svelte:head>
  <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "Modal",
      "url": "https://modal.com/"
    }
  </script>
</svelte:head>

<Landing />
