<script lang="ts">
  import type { Tweet } from "./tweet";

  export let tweet: Tweet;
  export let hideTopBorder = false;
</script>

<div
  class="inline-block border-b border-l border-light-green/30 flex-shrink-0 w-[360px] h-[320px]"
  class:border-t={!hideTopBorder}
>
  <div class="flex flex-col gap-4 p-8">
    <div class="flex items-center">
      <a
        target="_blank"
        rel="noreferrer"
        href={tweet.url}
        class="shrink-0 rounded-full w-10 h-10 overflow-hidden"
      >
        <img src={tweet.img} alt="Twitter profile {tweet.username}" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href={tweet.url}
        class="ml-4 flex flex-col"
      >
        <div class="font-bold text-light-green/80">{tweet.name}</div>
        <div class="text-light-green/70">{tweet.job_title}</div>
      </a>
    </div>
    {@html tweet.text}
  </div>
</div>
