<script lang="ts">
  import {
    BlocksIcon,
    BugOffIcon,
    CalendarClockIcon,
    DatabaseIcon,
    GlobeIcon,
    ImagesIcon,
  } from "lucide-svelte";

  import AccordionList from "../AccordionList.svelte";

  const features = [
    {
      icon: ImagesIcon,
      headline: "Flexible Environments",
      subline:
        "Bring your own image or build one in Python, scale resources as needed, and leverage state-of-the-art GPUs like H100s & A100s for high-performance computing.",
    },
    {
      icon: BlocksIcon,
      headline: "Seamless Integrations",
      subline:
        "Export function logs to Datadog or any OpenTelemetry-compatible provider, and easily mount cloud storage from major providers (S3, R2 etc.).",
    },
    {
      icon: DatabaseIcon,
      headline: "Data Storage",
      subline:
        "Manage data effortlessly with storage solutions (network volumes, key-value stores and queues). Provision storage types and interact with them using familiar Python syntax.",
    },
    {
      icon: CalendarClockIcon,
      headline: "Job Scheduling",
      subline:
        "Take control of your workloads with powerful scheduling. Set up cron jobs, retries, and timeouts, or use batching to optimize resource usage.",
    },
    {
      icon: GlobeIcon,
      headline: "Web Endpoints",
      subline:
        "Deploy and manage web services with ease. Create custom domains, set up streaming and websockets, and serve functions as secure HTTPS endpoints.",
    },
    {
      icon: BugOffIcon,
      headline: "Built-In Debugging",
      subline:
        "Troubleshoot efficiently with built-in debugging tools. Use the modal shell for interactive debugging and set breakpoints to pinpoint issues quickly.",
    },
  ];
</script>

<section class="bg-dark-gray py-24 md:py-48">
  <div class="marketing-container">
    <h2 class="marketing-h3 mb-12">Features</h2>

    <!-- Tablet and desktop, grid variant -->
    <div
      class="hidden md:block marketing-contained-negate-ml marketing-contained-negate-mr
      border-y border-light-green/30 min-[1400px]:border-x min-[1400px]:rounded-lg"
    >
      <div class="grid md:grid-cols-2 lg:grid-cols-3 border-light-green/30">
        {#each features as { icon, headline, subline }}
          <div
            class="md:px-10 lg:px-14 py-10 border-light-green/30
            md:even:border-l md:[&:nth-child(-n+4)]:border-b
            lg:[&:nth-child(4)]:border-b-0 lg:odd:border-l lg:[&:nth-child(3n+1)]:border-l-0"
          >
            <svelte:component this={icon} class="text-brand-green mb-6" />
            <p class="marketing-h4 mb-6">{headline}</p>
            <p class="text-light-green/60">{subline}</p>
          </div>
        {/each}
      </div>
    </div>

    <!-- Mobile collapsible list variant -->
    <div class="md:hidden">
      <AccordionList items={features} />
    </div>
  </div>
</section>
