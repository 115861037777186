<script>
  import VideoBackground from "$lib/ui/common/VideoBackground.svelte";
  import AccordionList from "../AccordionList.svelte";
  import MarketingButton from "../MarketingButton.svelte";

  const items = [
    {
      headline: "Built on top of gVisor",
      subline:
        "The secure application kernel for containers, providing top-tier isolation in multi-tenant setups.",
    },
    {
      headline: "SOC 2 and HIPAA",
      subline:
        "Fully compliant with SOC 2. Run HIPAA-compliant workloads. We have industry-standard security, availability, and confidentiality.",
    },
    {
      headline: "Region support",
      subline:
        "Deploy globally with enhanced compliance across geographic regions.",
    },
    {
      headline: "SSO sign in for enterprise",
      subline:
        "Enterprise-grade SSO for transparent, streamlined access management.",
    },
  ];
</script>

<section class="mt-8 mb-24 marketing-container">
  <div class="grid md:grid-cols-12 gap-5">
    <div class="md:col-span-5">
      <h2 class="marketing-h3 mb-8 md:mb-20">
        <span class="text-primary">Security</span> and governance
      </h2>
      <div class="hidden md:block">
        <AccordionList {items} />
        <div class="mt-14" />
        <MarketingButton href="/docs/guide/security">Learn More</MarketingButton
        >
      </div>
    </div>

    <div class="hidden md:block md:col-span-1" />

    <div class="md:col-span-6 order-1 mt-[-10px]">
      <div>
        <VideoBackground
          poster="https://modal-cdn.com/tmpikav2uo5_78a38e65.webp"
        >
          <source
            src="https://modal-cdn.com/landscape-vids/Modal_Security.mp4"
            type="video/mp4"
          />
        </VideoBackground>
      </div>
    </div>
  </div>

  <div class="md:hidden mt-12">
    <AccordionList {items} />
    <div class="mt-10" />
    <MarketingButton href="/docs/guide/security">Learn More</MarketingButton>
  </div>
</section>
