<script lang="ts">
  import BuildAnything from "./BuildAnything.svelte";
  import BuiltWithModal from "./BuiltWithModal.svelte";
  import CompanyBar from "./CompanyBar.svelte";
  import Differentiators from "./Differentiators.svelte";
  import FeaturesSection from "./FeaturesSection.svelte";
  import Hero from "./Hero.svelte";
  import PricingSection from "./PricingSection.svelte";
  import QuotesSection from "./QuotesSection.svelte";
  import SecuritySection from "./SecuritySection.svelte";
  import Testimonials from "./Testimonials.svelte";
  import UseCasesSection from "./UseCasesSection.svelte";
</script>

<main class="overflow-x-clip">
  <Hero />

  <CompanyBar />
  <Differentiators />

  <UseCasesSection />
  <BuildAnything />
  <FeaturesSection />
  <PricingSection />

  <SecuritySection />
  <BuiltWithModal />
  <QuotesSection />
  <Testimonials />
</main>
